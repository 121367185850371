import { HttpClient } from '@angular/common/http';
import { inject, Inject, Injectable } from '@angular/core';
import { APP_CONFIG, IAppConfig } from '@tcc-mono/shared/app-config';
import { Observable } from 'rxjs';
import { EnableTwoFactorResponse, Response, VerifyTwoFactorResponse } from '../models';


@Injectable({
  providedIn: 'root'
})
export class TwoFactorService {
  private readonly _env: IAppConfig = inject(APP_CONFIG);
  private readonly _http: HttpClient = inject(HttpClient);

  private readonly _BASE_URL = 'api/me/two-factor'

  public enableTwoFactor = (body: { [key: string]: any } = {}): Observable<EnableTwoFactorResponse> => {
    const url = `${this._env.authUrl}${this._BASE_URL}`;

    return this._http.post<EnableTwoFactorResponse>(url, body);
  }

  public verifyTwoFactor = (body: { token: string, code: string }): Observable<Response<VerifyTwoFactorResponse>> => {
    const url = `${this._env.authUrl}${this._BASE_URL}/verify`;

    return this._http.post<Response<VerifyTwoFactorResponse>>(url, body);
  }

  public disableTwoFactor = (): Observable<unknown> => {
    const url = `${this._env.authUrl}${this._BASE_URL}`;

    return this._http.delete(url)
  }

}
