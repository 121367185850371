import { inject, Pipe, PipeTransform } from '@angular/core';
import { IconName, IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { FontawesomeTypeService } from '../services/fontawesome-type.service';

@Pipe({
  name: 'globalFaStyle',
  pure: false // Needs to be impure to rerender pipe response when the language is changed
})
export class GlobalFaStylePipe implements PipeTransform {
  private readonly _fontawesomeTypeService: FontawesomeTypeService = inject(FontawesomeTypeService);

  transform = (value: IconName): Observable<[IconPrefix, IconName]> => {
    return this._fontawesomeTypeService.selectedFontawesomeType$
      .pipe(
        map((faType: IconPrefix) => ([faType, value]))
      );
  }

}
