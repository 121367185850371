// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import { IAppConfig } from '@tcc-mono/shared/app-config';

export const environment: IAppConfig = {
  version: '07-03-2025',
  production: false,
  apiUrl: 'https://vdv-api-develop.tccapps.nl/',
  authUrl: 'https://vdv-api-develop.tccapps.nl/',
  appCode: 'VDV-WEB',
  tccCoreAuthentication: {
    clientId: '9a703ee4-c2c7-4ec9-939c-6a4e3c5b8673',
    redirectUrl: window.location.origin + '/auth/callback'
  },
  vatPercentageOld: 9,
  vatPercentage: 21,
  weideToeslagExVatInEur: 0.10,
  environments: [
    {
      name: 'Kassa app',
      url: 'https://vandijk-app-develop.tccapps.nl',
      icon: 'cash-register'
    },
    {
      name: 'Koppels',
      url: 'https://vandijk-koppels-develop.tccapps.nl',
      icon: 'layer-group'
    },
    {
      name: 'Deense dieren',
      url: 'https://vandijk-deense-dieren-develop.tccapps.nl',
      icon: 'cow'
    },
    {
      name: 'Medicijn registratie (VH)',
      url: 'https://vandijk-medicatie-develop.tccapps.nl',
      icon: 'syringe'
    }
  ]
};


/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
