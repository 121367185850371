import { inject, Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { DOCUMENT } from '@angular/common';
import { AnimationClass, ANIMATIONS, LocalstorageKey } from '../..';

@Injectable({
  providedIn: 'root'
})
export class AnimationService {
  private readonly _rf = inject(RendererFactory2);
  private readonly _localStorageService = inject(LocalStorageService);
  private readonly _document = inject(DOCUMENT);

  private _animations = ANIMATIONS;
  private _renderer: Renderer2 = this._rf.createRenderer(null, null);

  private _ANIMATION_STORAGE_KEY = LocalstorageKey.Animation;

  private _selectedAnimationClass: string;

  public get selectedAnimationClass() {
    return this._selectedAnimationClass;
  }

  public get animations() {
    return this._animations;
  }

  public initTheme() {
    const currentAnimationClass = this._localStorageService.get<AnimationClass>(this._ANIMATION_STORAGE_KEY);

    this.setTheme(currentAnimationClass || AnimationClass.Medium);
  }

  public setTheme(className: string) {
    this._animations.forEach(a => {
      this._renderer.removeClass(this._document.body, a.className);
    });
    this._renderer.addClass(this._document.body, className);
    this._selectedAnimationClass = className;
    this._localStorageService.set({ key: LocalstorageKey.Animation, value: className });
  }

}

