import { inject, Injectable, ModuleWithProviders, NgModule } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TranslocoLoader, Translation, TRANSLOCO_CONFIG, TRANSLOCO_LOADER, TranslocoModule, translocoConfig } from '@ngneat/transloco';
import { Observable, zip, map } from 'rxjs';
import { TranslocoLocaleModule } from '@ngneat/transloco-locale';
import { LocalstorageKey } from '@tcc-mono/shared/ui/theming';

const appLanguages: string[] = ['nl', 'en'];

@Injectable({ providedIn: 'root' })
export class TranslocoHttpLoader implements TranslocoLoader {
  private readonly _http: HttpClient = inject(HttpClient);

  public getTranslation(lang: string): Observable<Translation> {
    return zip([
      this._http.get<Translation>(`/assets/i18n/${lang}.json`),
      this._http.get<Translation>(`/assets/i18n/base/${lang}.json`),
    ])
      .pipe(
        map(([trl, baseTrl]: [Translation, Translation]) => {
          return {
            ...trl,
            ...baseTrl
          }
        })
      );
  }
}

@NgModule({
  imports: [
    TranslocoLocaleModule.forRoot({
      langToLocaleMapping: {
        en: 'en-GB',
        nl: 'nl-NL'
      }
    })
  ],
  exports: [TranslocoModule]
})
export class SharedTranslationModule {
  public static forRoot(environment: any): ModuleWithProviders<SharedTranslationModule> {
    return {
      ngModule: SharedTranslationModule,
      providers: [
        {
          provide: TRANSLOCO_CONFIG,
          useValue: translocoConfig({
            availableLangs: appLanguages,
            defaultLang: localStorage.getItem(environment.appCode) ? JSON.parse(localStorage.getItem(environment.appCode))[LocalstorageKey.Language] : 'nl',
            // Remove this option if your application doesn't support changing language in runtime.
            reRenderOnLangChange: true,
            prodMode: environment.production,
          })
        },
        { provide: TRANSLOCO_LOADER, useClass: TranslocoHttpLoader }
      ]
    }
  }
}
