import { VanDijkWebShellRoutingModule } from './vandijk-web-shell-routing.module';
import { inject, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { RouteReuseStrategy } from '@angular/router';
import { IonicRouteStrategy } from '@ionic/angular';
import { AnimationService, FontawesomeTypeService, LanguageService } from '@tcc-mono/shared/ui/theming';
import { FontawesomeImportModule } from '@tcc-mono/vandijk/shared/ui/fontawesome-import';
import { AnimalSelectionShellModule } from '@tcc-mono/vandijk/shared/features/animal-selections/feature/animal-selection-shell';
import { TccCoreAuthModule } from '@tcc-mono/shared/authentication/tcc-core-auth';
import { fal } from '@fortawesome/pro-light-svg-icons';

@NgModule({
  imports: [
    BrowserModule,
    VanDijkWebShellRoutingModule,
    HttpClientModule,
    FontAwesomeModule,
    FontawesomeImportModule,
    TccCoreAuthModule.forRoot(),
    AnimalSelectionShellModule.forRoot({
      source: 'web'
    })
  ],
  providers: [
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy
    }
  ]
})
export class VanDijkWebShellModule {
  private readonly _animationService = inject(AnimationService);
  private readonly _fontawesomeService = inject(FontawesomeTypeService);
  private readonly _languageService = inject(LanguageService);
  private readonly _faLib = inject(FaIconLibrary);

  constructor() {
    this._animationService.initTheme();
    this._fontawesomeService.initTheme();
    this._languageService.initLang();
    this._faLib.addIconPacks(fal);
  }
}
