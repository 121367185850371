import { NgModule } from '@angular/core';
import { AreYouSureService } from './are-you-sure.service';

@NgModule({
  providers: [
    AreYouSureService
  ]
})
export class SharedUiServiceModule {

}
