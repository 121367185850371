import { inject, Injectable } from '@angular/core';
import { LoadingController, LoadingOptions } from '@ionic/angular';
import { from, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {
  private readonly _loadingCtrl: LoadingController = inject(LoadingController);

  /**
   * Show loading screen
   *
   * @param   {object}  options  Ionic loading properties
   * @return  {void}
   */

  public show = async (options: LoadingOptions = {}): Promise<any> => {

    /** Merge options with defaults */

    return this._loadingCtrl.create(Object.assign({

      spinner: 'crescent',
      duration: 0,
      translucent: true,
      backdropDismiss: false,
      keyboardClose: false
    }, options))
      .then(loader => loader.present())
  }

  /**
   * Dismiss loading screen
   *
   * @return  {void}
   */

  public dismiss = async (): Promise<boolean> => {
    return this._loadingCtrl ? this._loadingCtrl.dismiss() : Promise.resolve(true);
  }

  /**
 * Show loading screen
 *
 * @param   {object}  options  Ionic loading properties
 * @return  {Observable<unknown>}
 */
  public start = (options: LoadingOptions = {}): Observable<unknown> => {
    return from(this.show(options));
  }



}
