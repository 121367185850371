import { inject, Injectable } from '@angular/core';
import { AlertController, AlertOptions } from '@ionic/angular';
import { from, Observable } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class AreYouSureService {
  private readonly _alertCtrl: AlertController = inject(AlertController);

  public show = (opts?: Partial<AlertOptions>, confirmRoleName: string = 'delete'): Observable<boolean> => {
    const options: AlertOptions = {
      header: opts?.header || 'Weet u het zeker?',
      subHeader: opts?.subHeader,
      message: opts?.message || 'Dit item wordt permanent verwijderd.',
      cssClass: opts?.cssClass,
      inputs: opts?.inputs,
      buttons: opts?.buttons || [
        {
          text: 'Annuleren',
          role: 'cancel'
        },
        {
          text: 'Verwijderen',
          role: 'delete'
        }
      ],
      backdropDismiss: opts?.backdropDismiss || false,
      translucent: opts?.translucent || false,
      animated: opts?.animated === false ? false : true, // defaults to true
      mode: opts?.mode,
      keyboardClose: opts?.keyboardClose,
      id: opts?.id,
      enterAnimation: opts?.enterAnimation,
      leaveAnimation: opts?.leaveAnimation,
    };

    return from(this._alertCtrl.create(options))
      .pipe(
        tap(alert => alert.present()),
        switchMap(alert => alert.onDidDismiss()),
        map(({ role }) => role === confirmRoleName)
      );
  }

}
