export interface Environment {
  name: string;
  url: string;
  icon: string;
}

export interface IAppConfig {
  production: boolean;
  apiUrl: string;
  authUrl?: string;
  appCode: string;
  authentication?: IAppConfigAuthentication;
  version?: string;
  tccCoreAuthentication?: ITccCoreAuthentication;
  vatPercentage?: number,
  vatPercentageOld?: number,
  weideToeslagExVatInEur?: number,
  environments?: Environment[]
}

export interface IAppConfigAuthentication {
  clientId: string;
  scope: string;
  redirectUrl: string;
  responseType: string;
  codeChallengeMethod: string;
  renewTimeBeforeTokenExpiresInSeconds: number;
  postLogoutRedirectUrl: string;
  useTenants?: boolean;
  appCallback?: string;
}

export interface ITccCoreAuthentication {
  clientId: string;
  redirectUrl: string;
}
