import { BehaviorSubject } from 'rxjs';
import { inject, Injectable } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { IconPrefix } from '@fortawesome/fontawesome-svg-core';
import { LocalstorageKey, FontAwesomeType } from '../..';

@Injectable({
  providedIn: 'root'
})
export class FontawesomeTypeService {
  private readonly _localStorageService: LocalStorageService = inject(LocalStorageService);

  private _fontawesomeTypes = FontAwesomeType;
  private _STORAGE_KEY = LocalstorageKey.FontawesomeType;

  private _selectedFontawesomeType = new BehaviorSubject<IconPrefix>(null);
  public selectedFontawesomeType$ = this._selectedFontawesomeType.asObservable();

  public get fontawesomeTypes() {
    return this._fontawesomeTypes;
  }

  public initTheme() {
    const currentFontawesomeType = this._localStorageService.get<string>(this._STORAGE_KEY);

    this.setType(currentFontawesomeType || (FontAwesomeType.Regular as any));
  }

  public setType(type: IconPrefix) {
    this._selectedFontawesomeType.next(type);
    this._localStorageService.set({ key: this._STORAGE_KEY, value: type });
  }

}

